export const gradients = {
	seeMode: `linear-gradient(
            225deg,
            hsl(27deg 86% 86%) 0%,
            hsl(27deg 90% 85%) 10%,
            hsl(27deg 94% 84%) 20%,
            hsl(28deg 96% 82%) 30%,
            hsl(28deg 98% 81%) 40%,
            hsl(28deg 100% 80%) 50%,
            hsl(21deg 100% 82%) 60%,
            hsl(12deg 100% 85%) 70%,
            hsl(2deg 97% 87%) 80%,
            hsl(352deg 74% 86%) 90%,
            hsl(341deg 51% 85%) 100%
      )`,
	eCommerce: `linear-gradient(  45deg,
            hsl(334deg 74% 74%) 0%,
            hsl(339deg 83% 74%) 10%,
            hsl(345deg 90% 75%) 20%,
            hsl(350deg 95% 75%) 30%,
            hsl(355deg 99% 75%) 40%,
            hsl(0deg 100% 75%) 50%,
            hsl(0deg 100% 72%) 60%,
            hsl(1deg 100% 70%) 70%,
            hsl(1deg 100% 67%) 80%,
            hsl(1deg 100% 65%) 90%,
            hsl(0deg 100% 62%) 100%)`,
	designSystem: `linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)`,
};
