import React from "react";
import "./gradientBox.scss";

const GradientBox = ({
	children,
	borderRadius,
	className,
	borderWidth,
	padding,
}) => {
	return (
		<div
			className={`gradientBoxOuter ${className}`}
			style={{ borderRadius: borderRadius, padding: borderWidth }}
		>
			<div
				className={`gradientBoxInner`}
				style={{ borderRadius: borderRadius, padding: padding }}
			>
				{children}
			</div>
		</div>
	);
};

export default GradientBox;
