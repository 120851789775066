/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Target, HeartPulse, PersonStanding, Zap, HeartHandshake, Component, Code, Award, Paintbrush, ArrowRight} from "lucide-react";
import designsprint from "./images/designsprint.jpg";
import vision_animation from "./images/vision.gif";
import mobilepp from "./images/mobilepp.gif";
import vision from "./images/vision.jpg";
import fakegoogle1 from "./images/fakegoogle_1.jpg";
import fakegoogle2 from "./images/fakegoogle_2.jpg";
import fakegoogle3 from "./images/fakegoogle_3.jpg";
import fakegoogle4 from "./images/fakegoogle_4.jpg";
import fakegoogle5 from "./images/fakegoogle_animation.gif";
import colorconfiganimation from "./images/colour_config.gif";
import productdiscovery from "./images/productdiscovery.gif";
import productdiscoveryold from "./images/productdiscovery_old.jpg";
import productconfigold from "./images/productconfigold.jpg";
import outcome from "./images/outcome.jpg";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    span: "span",
    p: "p",
    ul: "ul",
    li: "li",
    blockquote: "blockquote"
  }, _provideComponents(), props.components), {MarkdownBox, InfoGrid, InfoGridItem, Stack, StackItem} = _components;
  if (!InfoGrid) _missingMdxReference("InfoGrid", true);
  if (!InfoGridItem) _missingMdxReference("InfoGridItem", true);
  if (!MarkdownBox) _missingMdxReference("MarkdownBox", true);
  if (!Stack) _missingMdxReference("Stack", true);
  if (!StackItem) _missingMdxReference("StackItem", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "the-business"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#the-business"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "THE BUSINESS"), "\n", React.createElement(_components.p, null, "Redbubble is the leading global marketplace for independent artists to sell their creations on commercial goods like t-shirts, stickers and phone cases. It was founded in 2006 and has grown to support close to 1 million artists that sold to 4 million customers in 2018 alone."), "\n", React.createElement(_components.p, null, "It is available in multiple languages and has offices in Australia, North America and Europe, with North America being the largest market."), "\n", React.createElement(_components.h2, {
    id: "my-role"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#my-role"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "MY ROLE"), "\n", React.createElement(_components.p, null, "My role as Senior Product Designer was to uncover user needs, validate our hypotheses, contribute to overall strategy, and work with the team on coming up with a lean approach to delivering our solution."), "\n", React.createElement(MarkdownBox, {
    size: "medium",
    bordered: true,
    label: "HIGHLIGHTS"
  }, React.createElement(InfoGrid, null, React.createElement(InfoGridItem, {
    title: "UI",
    icon: React.createElement(Paintbrush, {
      size: "32"
    }),
    caption: "Solving interaction and hierarchy challenges"
  }), React.createElement(InfoGridItem, {
    title: "UX",
    icon: React.createElement(Code, {
      size: "32"
    }),
    caption: "Performing qualitative and quantitative research"
  }), React.createElement(InfoGridItem, {
    title: "Dev",
    icon: React.createElement(Code, {
      size: "32"
    }),
    caption: "Prototyping using real code for user testing and performance"
  }))), "\n", React.createElement(_components.h2, {
    id: "the-problem"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#the-problem"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "THE PROBLEM"), "\n", React.createElement(_components.p, null, "The Redbubble platform had evolved over 12 years and accumulated a lot of technical debt. It was using old tech which was causing issues for teams as well as customers since it resulted in:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Slow product development"), "\n", React.createElement(_components.li, null, "Poor site performance"), "\n"), "\n", React.createElement(_components.h2, {
    id: "the-objective"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#the-objective"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "THE OBJECTIVE"), "\n", React.createElement(_components.p, null, "Deliver a new front-end platform and be the fastest, unique shopping experience our customers had ever seen."), "\n", React.createElement(_components.h2, {
    id: "strategy"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#strategy"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "STRATEGY"), "\n", React.createElement(_components.p, null, "An initial challenge was to reduce scope. Delivering a new platform with the same feature set as we already had would take a minimum of 12 months."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "So how could we go to market early and be lean, deliver incrementally and validate both the new platform and experience?"), "\n"), "\n", React.createElement(_components.p, null, "We started looking at analytics data, targeting different user segments and their behaviours to identify an opportunity where we could show a particular segment an experience that was different from the rest of the users on the site. What we found was that a significant amount of traffic to our product pages came from Google Shopping and the majority of these users would bounce within a minute."), "\n", React.createElement(_components.p, null, "This was ideal as it allowed us to show this segment a different experience without it interferring with existing members and customers using the site. It also allowed us to deliver incrementally, and to further narrow down the scope we decided to focus on mobile users, and the t-shirt product page."), "\n", React.createElement(_components.p, null, "This way our initial scope and approach was reduced to:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Google Shopping users"), "\n", React.createElement(_components.li, null, "Mobile devices"), "\n", React.createElement(_components.li, null, "T-Shirt product page"), "\n"), "\n", React.createElement(_components.h2, {
    id: "research"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#research"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "RESEARCH"), "\n", React.createElement(_components.p, null, "I ran a series of qualitative interviews with people using Google Shopping on their mobile device. The goal was to better understand their general needs and behaviours."), "\n", React.createElement(_components.p, null, "The sessions were generative, with little guidance and the task was simple: find a t-shirt with a graphics print that you like in Google Shopping."), "\n", React.createElement(_components.p, null, "Some key insights came out of that research:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Users make decisions, eg. style, colour and fit, before arriving to a retailer"), "\n", React.createElement(_components.li, null, "Users were happy to buy from unknown retailers but it would take more for them to trust the site"), "\n", React.createElement(_components.li, null, "Price, delivery dates and returns policy are key factors when users decide on making a purchase"), "\n", React.createElement(_components.li, null, "Reviews are important to establish trust in both the brand and how the product fits"), "\n"), "\n", React.createElement(_components.h2, {
    id: "testing-with-confidence"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#testing-with-confidence"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "TESTING WITH CONFIDENCE"), "\n", React.createElement(_components.p, null, "One issue with the above method was that only a few of the participants would naturally land on Redbubble when using Google Shopping. This meant that we didn't have many specific insights around their behaviours on our own product page."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "So how might we incorporate the Google Shopping flow in a natural way, but ensure that the users end up on Redbubble?"), "\n"), "\n", React.createElement(_components.p, null, "I came up with the idea and built our own fake version of a Google Search page with simple HTML, CSS and Javascript. It had a search form that would take the users search query and apply filters to it. This allowed us to alter the search results in Google Shopping to only show results from Redbubble and enabled the user to search and discover products naturally."), "\n", React.createElement(MarkdownBox, {
    size: "medium"
  }, React.createElement(Stack, null, React.createElement(StackItem, {
    variant: "mobile",
    image: fakegoogle1,
    caption: "Search in fake Google",
    label: "1"
  }), React.createElement(StackItem, {
    variant: "mobile",
    image: fakegoogle2,
    caption: "See real Google Shopping results",
    label: "2"
  }), React.createElement(StackItem, {
    variant: "mobile",
    image: fakegoogle3,
    caption: "View products",
    label: "3"
  }), React.createElement(StackItem, {
    variant: "mobile",
    image: mobilepp,
    caption: "Arrive on Redbubble",
    label: "4"
  }))), "\n", React.createElement(_components.h2, {
    id: "insights"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#insights"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "INSIGHTS"), "\n", React.createElement(_components.p, null, "The above set up allowed us to explore our Google Shopping users behaviours more specifically once they had arrived on Redbubble."), "\n", React.createElement(_components.p, null, "Some key insights from this research was:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Trust and confidence came from others (reviews and comments)"), "\n", React.createElement(_components.li, null, "Users didn't scroll past half the page"), "\n", React.createElement(_components.li, null, "Confinguring the product required a lot of effort"), "\n", React.createElement(_components.li, null, "The majority of options we provided were not relevant"), "\n", React.createElement(_components.li, null, "Users struggled to find key information"), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-sprint"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#design-sprint"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "DESIGN SPRINT"), "\n", React.createElement(_components.p, null, "To kick things off my product manager and I co-facilitated a three day design sprint with our product team. We shared and discussed the insights and data. We looked at constraints, aligned on goals and explored new concepts."), "\n", React.createElement("img", {
    className: "markdownImage",
    src: designsprint,
    alt: "team collaborating in office space in front of screens and whiteboard"
  }), "\n", React.createElement(_components.h2, {
    id: "vision"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#vision"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "VISION"), "\n", React.createElement(_components.p, null, "As we brainstormed, great concepts and ideas started to emerge. But they often felt narrow and too focused on a specific user segment."), "\n", React.createElement(_components.p, null, "For example: A new user cares more about reviews than an existing member. And a user coming from Google Shopping has different needs from someone coming from an artist profile page."), "\n", React.createElement(_components.p, null, "This lead us to another, perhaps bigger question:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "How might we serve the unique needs of our many user segments, equally well?"), "\n"), "\n", React.createElement(_components.p, null, "Thus, our vision of a modular experience that would adapt to user signals and intent was born."), "\n", React.createElement(MarkdownBox, {
    size: "medium"
  }, React.createElement(Stack, null, React.createElement(StackItem, {
    variant: "plain",
    image: vision,
    caption: "Modular experiences"
  }), React.createElement(StackItem, {
    variant: "plain",
    image: vision_animation,
    caption: "Signal based layouts"
  }))), "\n", React.createElement(_components.h2, {
    id: "solutions"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#solutions"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "SOLUTIONS"), "\n", React.createElement(_components.p, null, "Our vision helped guide our decision making both technically and for the interface. It also allowed us to move forward with a focus on the Google Shopping user in mind."), "\n", React.createElement(_components.p, null, "I took the many ideas and prototypes from our design sprint, further refined them for validation, and focused on three main areas:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Product configuration"), "\n", React.createElement(_components.li, null, "Product discovery"), "\n", React.createElement(_components.li, null, "Product trust"), "\n"), "\n", React.createElement(_components.h2, {
    id: "product-configuration"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#product-configuration"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "PRODUCT CONFIGURATION"), "\n", React.createElement(_components.p, null, "The current experience required the user to scroll exessively to configure the product. Our data showed that the main attribute to change was colour. It also showed that more than 90% of purchases were made on white, black or grey coloured t-shirts and that the majority of purchases were made on the default colour for the product."), "\n", React.createElement(_components.p, null, "I came up with a few ideas and tested three main hypotheses:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Keeping the colour configurator within the viewport will reduce the time it takes to configure the product"), "\n", React.createElement(_components.li, null, "Sorting colours based on usage will reduce the time it takes to choose colour"), "\n", React.createElement(_components.li, null, "De-emphasising the configuration will reduce cognitive load and make the experience feel less overwhelming"), "\n"), "\n", React.createElement(MarkdownBox, {
    size: "medium"
  }, React.createElement(Stack, null, React.createElement(StackItem, {
    variant: "mobile",
    image: colorconfiganimation,
    label: "NEW"
  }), React.createElement(StackItem, {
    variant: "plain",
    col: "3",
    image: productconfigold,
    label: "OLD"
  }))), "\n", React.createElement(_components.h2, {
    id: "product-discovery--trust"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#product-discovery--trust"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "PRODUCT DISCOVERY & TRUST"), "\n", React.createElement(_components.p, null, "An interesting insight from our research was that many users didn't scroll past the first third of the page. In fact, they would stop where the \"Add to cart\" button was and scroll back up. At the end of these sessions, I would share my observations with them and ask why that was. They were often surprised themselves, did it mostly subconsciously and would say \"I guess I didn't think there was anything relevant below that button\"."), "\n", React.createElement(_components.p, null, "This was interesting since we had reviews, returns policy and delivery dates below that button. Things I knew were relevant to the user when making purchasing decisions and trusting a new retailer."), "\n", React.createElement(_components.p, null, "I came up with a few ideas and tested three main hypotheses:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Moving the \"Add to cart\" button to the top and making it fixed will encourage scrolling"), "\n", React.createElement(_components.li, null, "Grouping information will make it easier to scan and find"), "\n", React.createElement(_components.li, null, "Emphasising important content will reduce the time it takes to make a purchasing decision"), "\n"), "\n", React.createElement(MarkdownBox, {
    size: "medium"
  }, React.createElement(Stack, null, React.createElement(StackItem, {
    variant: "mobile",
    image: productdiscovery,
    label: "NEW"
  }), React.createElement(StackItem, {
    variant: "plain",
    col: "3",
    image: productdiscoveryold,
    label: "OLD"
  }))), "\n", React.createElement(_components.h2, {
    id: "outcomes"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#outcomes"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "OUTCOMES"), "\n", React.createElement(_components.p, null, "The overall initiative was a massive undertaking. We were able to break down and sequence our delivery with incremental additions to the experience and measure our impact along the way."), "\n", React.createElement(_components.p, null, "In the end our solution had a big impact on our key metrics and also triggered new and exciting initiatives:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Our MVP saw record increases in conversion and add to cart rates"), "\n", React.createElement(_components.li, null, "The new platform is adopted across the organisation"), "\n", React.createElement(_components.li, null, "Our visual language was refined and evolved"), "\n", React.createElement(_components.li, null, "We established the foundations for a ", React.createElement("a", {
    href: "/rb-design-system",
    target: "_blank"
  }, "Design System")), "\n"), "\n", React.createElement("img", {
    className: "markdownImage",
    src: outcome,
    alt: "vision outcome"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
