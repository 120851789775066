import React from "react";
import {
	Home,
	Briefcase,
	Wrench,
	Globe2,
	Bot,
	Code2,
	HeartHandshake,
	MousePointerClick,
	PersonStanding,
	Rocket,
	Linkedin,
	Github,
	Component,
} from "lucide-react";
import Spot from "../components/Spot/spot.js";
import { gradients } from "../utils/gradients.js";
import Heading from "../components/Heading/heading.js";
import MarkdownBox from "../components/MarkdownBox/markdownBox.js";
import List, { ListItem, ListSeparator } from "../components/List/list.js";
import Weather from "../components/Weather/weather.js";
import GradientText from "../components/GradientText/gradientText.js";
// import SpotExternal from "../components/SpotExternal/spotExternal.js";
import SeemodeContent from "../content/seemode.mdx";
import RbppContent from "../content/rbpp.mdx";
import RbdsContent from "../content/rbds.mdx";
import rbdsVideo from "../assets/rbds.mp4";
import rbdsVideoUnderlay from "../assets/rbdsbg.jpg";
import seemodeVideo from "../assets/seemode.mp4";
import seemodeVideoUnderlay from "../assets/seemodebg.jpg?123";
import rbppVideo from "../assets/rbpp.mp4";
import rbppVideoUnderlay from "../assets/rbbg.jpg";
import smlogo from "../assets/seemode_logo.svg";
import rblogo from "../assets/RB_White_RGB.svg";
import InfoGrid, { InfoGridItem } from "../components/InfoGrid/infoGrid.js";
import { AnimatePresence } from "framer-motion";

// const isBrowser = typeof window !== "undefined";

const Layout = ({ children, location }) => {
	// const [isDark, setDark] = React.useState(
	// 	isBrowser
	// 		? window.matchMedia("(prefers-color-scheme: dark)").matches
	// 		: false
	// );

	return (
		<main>
			<div className="main">
				{/* <Background isDark={isDark} /> */}
				<Weather isDark={true} />
				<div className="block">
					<div className="hero">
						<h1 className="display name text">
							<div>Marcus</div>
							<div>Hammarstedt</div>
						</h1>
					</div>
				</div>
				<div className="blockConnectorContainer aboutBlock">
					<div className="blockConnector">
						<div className="blockConnectorIndicator">
							<div className="blockConnectorIcon">
								<Bot size={32} />
							</div>
						</div>
					</div>
					<div className="blockConnectorDescription">
						<Heading>TLDR</Heading>
						<div className="blockConnectorCaption">
							<GradientText>
								I'm a highly adaptable designer with a passion for crafting
								intuitive and efficient products
							</GradientText>
						</div>
						<p className="blockConnectorSubCaption">
							I use code and design to produce the highest quality work that
							delivers business value and makes my team proud.
						</p>
					</div>
					<div className="introBlock">
						<div className="introCorner" />
						<div className="introHorizontal" />
						<div className="intro">
							<div>
								<Heading>ROLES</Heading>
								<List>
									<ListItem
										indicator="2020"
										link="https://www.linkedin.com/company/see-mode-technologies/"
										linkTitle="@See-Mode"
									>
										Head of Design
									</ListItem>
									<ListItem
										indicator="2018"
										link="https://www.linkedin.com/company/redbubble/"
										linkTitle="@Redbubble"
									>
										Lead Design Technologist
									</ListItem>
									<ListItem
										indicator="2016"
										link="https://www.linkedin.com/company/redbubble/"
										linkTitle="@Redbubble"
									>
										Senior Product Designer
									</ListItem>
									<ListItem
										indicator="2014"
										link="https://www.linkedin.com/company/intershop-communications/"
										linkTitle="@Intershop"
									>
										Senior UI Developer
									</ListItem>
								</List>
							</div>
							<div>
								<Heading>AT A GLANCE</Heading>
								<List>
									<ListItem indicator={<Wrench />}>
										UI
										<ListSeparator />
										UX
										<ListSeparator />
										HTML
										<ListSeparator />
										CSS
										<ListSeparator />
										Javascript
									</ListItem>
									<ListItem indicator={<Briefcase />}>
										Remote
										<ListSeparator />
										Hybrid
									</ListItem>
									<ListItem indicator={<Home />}>Santa Cruz, CA</ListItem>
									<ListItem indicator={<Globe2 />}>
										USA <ListSeparator />
										<span className="muted">Australia</span>
										<ListSeparator />
										<span className="muted">Sweden</span>
									</ListItem>
								</List>
							</div>
						</div>
					</div>
				</div>
				<div className="block skillHighlights">
					<MarkdownBox size="fluid" bordered label="CORE SKILLS">
						<InfoGrid>
							<InfoGridItem
								icon={<MousePointerClick size="32" />}
								title="UI"
								caption="Designing and building intuitive and accessible interfaces for
										complex use cases such as medical diagnostics."
							/>
							<InfoGridItem
								icon={<PersonStanding size="32" />}
								caption="Designing, preparing and conducting qualitative and
										quantitative research to inform decision making."
								title="UX"
							/>
							<InfoGridItem
								icon={<Code2 size="32" />}
								title="Dev"
								caption="Deploying performant and accessible code to production environments through Git workflows."
							/>
							<InfoGridItem
								icon={<Component size="32" />}
								title="Design Systems"
								caption="Creating, maintainting and educating on Design
										Systems for small and large teams."
							/>
							<InfoGridItem
								icon={<HeartHandshake size="32" />}
								title="People"
								caption="Defining and running inclusive hiring processes. Onboarding,
										mentoring and coaching others."
							/>
							<InfoGridItem
								icon={<Rocket size="32" />}
								title="Team"
								caption="Planning and leading sprints, introducing new team processes
										and company policies."
							/>
						</InfoGrid>
					</MarkdownBox>
				</div>
				<div className="blockConnectorContainer">
					<div className="blockConnector">
						<div className="blockConnectorIndicator">
							<div className="blockConnectorIcon">
								<Briefcase size={32} />
							</div>
						</div>
					</div>
					<div className="blockConnectorDescription">
						<Heading>EXPERIENCE</Heading>
						<p>
							<GradientText className="blockConnectorCaption">
								With many years in the industry I've had the chance to work with
								some amazing people on some really interesting products
							</GradientText>
						</p>
						<p className="blockConnectorSubCaption">
							I've taken new products to market, lead product teams, built and
							educated on design systems, spearheaded accessibility initiatives
							and delivered solutions quickly by being lean and pragmatic.
						</p>
					</div>
				</div>
				<div className="block spotBlock">
					<Spot
						title="See-Mode"
						subtitle="Startup"
						video={seemodeVideo}
						bgImage={seemodeVideoUnderlay}
						bgColor="rgb(255, 207, 196)"
						videoBg="#0b161a"
						theme="light"
						description="Taking a new product to market globally as quickly as possible in the challenging medical technology and health space."
						bgGradient={gradients.seeMode}
						labels={["2020 - 2023"]}
						markdown={<SeemodeContent />}
						logo={smlogo}
						isMaximized={location.pathname === "/see-mode/"}
						position="first"
						to="/see-mode"
						customTheme="seemode"
					/>
					<Spot
						title="Redbubble"
						subtitle="Design System"
						video={rbdsVideo}
						bgImage={rbdsVideoUnderlay}
						bgColor="rgb(241, 235, 242)"
						bgGradient={gradients.designSystem}
						videoBg="#ededf3"
						theme="light"
						description="Establishing and maintaining a Design System to unite teams around a shared visual language and empower them to create scalable, high-quality products quickly."
						labels={["2018 - 2020"]}
						markdown={<RbdsContent />}
						logo={rblogo}
						isMaximized={location.pathname === "/rb-design-system/"}
						position="left"
						to="/rb-design-system"
						customTheme="rbds"
					/>
					<Spot
						title="Redbubble"
						subtitle="Product Page"
						video={rbppVideo}
						bgImage={rbppVideoUnderlay}
						bgColor="rgb(255, 232, 133)"
						videoBg="#eeeeee"
						ratio="portrait"
						description="Re-imagining the mobile product page experience as part of a large re-platforming initiative with a focus on performance and scalability."
						bgGradient={gradients.eCommerce}
						theme="light"
						labels={["2016 - 2017"]}
						markdown={<RbppContent />}
						logo={rblogo}
						isMaximized={location.pathname === "/rb-product-page/"}
						to="/rb-product-page"
						customTheme="rbpp"
					/>
				</div>
				<div className="blockConnectorContainer">
					<div className="blockConnector">
						<div className="blockConnectorIndicator">
							<div className="blockConnectorIcon">
								<Rocket size={32} />
							</div>
						</div>
					</div>
					<div className="blockConnectorDescription">
						<Heading>THE HORIZON</Heading>
						<div className="blockConnectorCaption">
							<GradientText>
								I've recently moved to Santa Cruz, California with my partner
								and I'm excited to find a meaningful role where I can have an
								impact
							</GradientText>
						</div>
						<p className="blockConnectorSubCaption">
							That may be working on Design Systems, rapidly experimenting for
							growth, helping take new products to market, or simply consulting
							on short term initiatives.
						</p>
						<p className="blockConnectorSubCaption">
							If you're looking for someone and think I might be a good fit,
							don't hesitate to reach out.
						</p>
					</div>
					<div className="introBlock">
						<div className="introCorner" />
						<div className="introHorizontal" />
						<div className="intro">
							<div>
								<List>
									<ListItem
										indicator={<Linkedin />}
										link="https://www.linkedin.com/in/marcushammarstedt/"
									>
										<a
											href="https://www.linkedin.com/in/marcushammarstedt/"
											target="_blank"
											rel="noreferrer"
										>
											LinkedIn
										</a>
									</ListItem>
									<ListItem
										indicator={<Github />}
										link="https://www.linkedin.com/in/marcushammarstedt/"
									>
										<a
											href="https://github.com/marchamm"
											target="_blank"
											rel="noreferrer"
										>
											GitHub
										</a>
									</ListItem>
								</List>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="block playgroundsContainer">
						<Heading highlight>PLAYGROUNDS</Heading>
						<div className="playSpots">
							<SpotExternal
								title="Burst plugin"
								description="I developed this plugin as a fun little side project for a previous portfolio. It simply takes any element, clones and animates it in fun and different ways on any device."
								labels={["javascript"]}
								link="https://github.com/marchamm/burst-animation"
							/>
							<SpotExternal
								title="Configuration"
								description="As part of a redesign of an e-commerce product page I was exploring different configuration patterns. This prototype is an example of how I approached it."
								labels={["HTML", "CSS", "JAVASCRIPT"]}
								link="https://cdpn.io/marchamm/debug/pVdzGY"
							/>
							<SpotExternal
								title="Design at scale"
								description="When designing for multiple languages, themes and devices I find that it helps to have prototypes that are configurable to ensure that we handle different scenarios gracefully."
								labels={["HTML", "CSS", "REACT"]}
								link="http://pp-config.branch.redbubble.design/"
							/>
						</div>
					</div> */}
				<div className="block" />
			</div>
			<AnimatePresence initial={false}>{children}</AnimatePresence>
		</main>
	);
};

export default Layout;
