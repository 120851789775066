/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Target, HeartPulse, PersonStanding, Zap, HeartHandshake, Component, Code, Award, Paintbrush} from "lucide-react";
import before1 from "./images/before1.jpg";
import after1 from "./images/after1.jpg";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    span: "span",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {MarkdownBox, InfoGrid, InfoGridItem, Stack, StackItem} = _components;
  if (!InfoGrid) _missingMdxReference("InfoGrid", true);
  if (!InfoGridItem) _missingMdxReference("InfoGridItem", true);
  if (!MarkdownBox) _missingMdxReference("MarkdownBox", true);
  if (!Stack) _missingMdxReference("Stack", true);
  if (!StackItem) _missingMdxReference("StackItem", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "the-business"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#the-business"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "THE BUSINESS"), "\n", React.createElement(_components.p, null, "Redbubble is the leading global marketplace for independent artists to sell their creations on commercial goods like t-shirts, stickers and phone cases. It was founded in 2006 and has grown to support close to 1 million artists that sold to 4 million customers in 2018 alone."), "\n", React.createElement(_components.p, null, "It is available in multiple languages and has offices in Australia, North America and Europe, with North America being the largest market."), "\n", React.createElement(_components.h2, {
    id: "my-role"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#my-role"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "MY ROLE"), "\n", React.createElement(_components.p, null, "In my role as Lead Design Technologist I acted as the bridge between design and engineering. I interviewed and surveyed our designers, engineers and product managers to inform our teams roadmap and I collaborated closely with designers."), "\n", React.createElement(_components.p, null, "I also continously evolved our documentation, built new React components and brought our marketing and product departments closer together."), "\n", React.createElement(MarkdownBox, {
    size: "medium",
    bordered: true,
    label: "HIGHLIGHTS"
  }, React.createElement(InfoGrid, null, React.createElement(InfoGridItem, {
    title: "Design",
    icon: React.createElement(Paintbrush, {
      size: "32"
    }),
    caption: "Defined and evolved new visual languages and themes"
  }), React.createElement(InfoGridItem, {
    title: "Code",
    icon: React.createElement(Code, {
      size: "32"
    }),
    caption: "Built react components and defined design tokens"
  }), React.createElement(InfoGridItem, {
    title: "Leadership",
    icon: React.createElement(Award, {
      size: "32"
    }),
    caption: "Defined a new role and career track for designers"
  }), React.createElement(InfoGridItem, {
    title: "Collaboration",
    icon: React.createElement(HeartHandshake, {
      size: "32"
    }),
    caption: "Worked closely with other teams on protoypes and design"
  }), React.createElement(InfoGridItem, {
    title: "Performance",
    icon: React.createElement(Zap, {
      size: "32"
    }),
    caption: "Investigated and fixed performance issues"
  }), React.createElement(InfoGridItem, {
    title: "Accessibility",
    icon: React.createElement(PersonStanding, {
      size: "32"
    }),
    caption: "Delivered accessible solutions and documentation "
  }))), "\n", React.createElement(_components.h2, {
    id: "the-problem"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#the-problem"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "THE PROBLEM"), "\n", React.createElement(_components.p, null, "Back in 2017 Redbubble had around 250 employees spread out over three continents: Australia, North America and Europe. About a hundred of those were engineers and close to twenty were designers across product and creative."), "\n", React.createElement(_components.p, null, "We didn't have a Design System at the time. However, we had a brand styleguide and processes like design critiques and #workingon channels in Slack to help us stay aligned on visual treatment as a design team. Even still, the overall product was starting to feel fragmented and inconsistent. Colours, spacing, motion, patterns and overall visual style had started to diverge in different directions."), "\n", React.createElement(_components.p, null, "This was the result of a few key factors:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Teams running multiple experiments with minimal investment"), "\n", React.createElement(_components.li, null, "Not enough front-end expertise"), "\n", React.createElement(_components.li, null, "An old code base with a mix of styles and technologies"), "\n"), "\n", React.createElement(_components.h2, {
    id: "the-beginning"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#the-beginning"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "THE BEGINNING"), "\n", React.createElement(_components.p, null, "Since we had just delivered a ", React.createElement("a", {
    href: "/rb-product-page/",
    target: "_blank"
  }, "new platform"), " that was starting to get adopted by teams. We found ourselves in a position where if we also delivered a Design System, we could help those same teams get their experiences on the new platform faster and with consistent styles and behaviours."), "\n", React.createElement(_components.p, null, "A technical lead and product manager was also excited about this opportunity. Together we collaborated and explored what we would do, and what it would look like, if we had a dedicated Design System team."), "\n", React.createElement(_components.h2, {
    id: "evangelising-the-system"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#evangelising-the-system"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "EVANGELISING THE SYSTEM"), "\n", React.createElement(_components.p, null, "We had some momentum from working on the new platform where we started to lay the foundations for a Design System. However, it wasn't enough to make it useful for product teams and would require dedicated resources to properly take off."), "\n", React.createElement(_components.p, null, "With this in mind, we took every chance we could to present and talk about the value and opportunities that a Design System could bring. I developed a roadmap outlining the focus areas and how this tied back in with business and team objectives. I performed UI audits and highlighted opportunities for consolidation and optimisation. And eventually, we got buy-in from the business to invest two resources, myself and a technical lead, to start delivering on the promise we had made around the value of a Design System."), "\n", React.createElement(_components.h2, {
    id: "initial-strategy"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#initial-strategy"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "INITIAL STRATEGY"), "\n", React.createElement(_components.p, null, "At first we wanted to establish a distributed model where we encourage contributions from other teams. We introduced new processes and ran workshops to facilitate a shared understanding of the direction and the value of the system. We felt this would be the quickest way to share the benefits of using the system with people, grow the component library and generate excitement."), "\n", React.createElement(_components.h2, {
    id: "what-we-learnt"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#what-we-learnt"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "WHAT WE LEARNT"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Processes caused friction and teams did not feel they got a return on the time they invested"), "\n", React.createElement(_components.li, null, "Conveying the value of a Design System without having one is difficult"), "\n", React.createElement(_components.li, null, "Getting the vision for the system in place first is important"), "\n", React.createElement(_components.li, null, "Deciding on consistent component API's is difficult when starting out"), "\n"), "\n", React.createElement(_components.h2, {
    id: "new-strategy"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#new-strategy"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "NEW STRATEGY"), "\n", React.createElement(_components.p, null, "We realised that we had to rethink our approach and that our best chance for success would be to work closely with specific product teams and use the system to assist them in their delivery."), "\n", React.createElement(_components.p, null, "To achieve this we identified a few teams within the business where a Design System could have the biggest impact. I worked closely with the designers on these teams, collaborated on solutions and identified what we could break down into smaller parts and add to the system. We then checked in regularly to make sure that the direction we were taking with the system and our components would meet the team' needs and expectations."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pair with design & engineering"), "\n", React.createElement(_components.li, null, "Plan based on team roadmaps"), "\n", React.createElement(_components.li, null, "Perform regular surveys and interviews"), "\n"), "\n", React.createElement(_components.h2, {
    id: "what-we-learned"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#what-we-learned"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "WHAT WE LEARNED"), "\n", React.createElement(_components.p, null, "With this new approach our product teams were able to deliver their experiences much faster, since the components they needed were already available for them in the system. It ensured consistency across all new experiences being delivered and it helped us as a Design System team with planning and building components that would deliver immediate value."), "\n", React.createElement(_components.h2, {
    id: "outcomes"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#outcomes"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "OUTCOMES"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Faster component library growth, 12 components in 12 weeks"), "\n", React.createElement(_components.li, null, "More immediate value to customers"), "\n", React.createElement(_components.li, null, "Recognition and customer satisfaction increase"), "\n", React.createElement(_components.li, null, "Adoption increase in supporting apps went from 0% to ~50% across the whole code base in one quarter"), "\n"), "\n", React.createElement(_components.h2, {
    id: "the-system"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#the-system"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "THE SYSTEM"), "\n", React.createElement(_components.p, null, "Within a year our Design System was the natural way to build experiences for our teams. We achieved full adoption across our main product experiencs which resulted in reduced delivery times and higher consistency."), "\n", React.createElement(_components.p, null, "Beyond components and styles we built out the support for multiple themes, improved the overall accessibility of the site and introduced prototyping tools like Playroom to make exploration fun and easy."), "\n", React.createElement(_components.p, null, "I also brought our creative and product departments closer together by establishing new rituals and collaborating on guidelines. Thus empowering the creative team to also contribute to the system and think about how their work impacts the overall product."), "\n", React.createElement(_components.p, null, "What started with a single colour guide and one component. In the end it hosted over 40 components, 3 themes and 20 guides for use by our product designers, marketing designers, engineers and external contractors."), "\n", React.createElement(_components.p, null, "Learn more: ", React.createElement("a", {
    href: "https://redbubble.design",
    target: "_blank"
  }, "redbubble.design")), "\n", React.createElement(MarkdownBox, {
    size: "large"
  }, React.createElement(Stack, null, React.createElement(StackItem, {
    variant: "desktop",
    image: before1,
    caption: "Initial status of our Design System"
  }), React.createElement(StackItem, {
    variant: "desktop",
    image: after1,
    caption: "Where it was at when I left Redbubble in 2020"
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
