import React, { useState } from "react";
import { Link } from "gatsby";
import { ArrowUpLeft } from "lucide-react";
import GradientBox from "../GradientBox/gradientBox.js";
import { motion, useInView } from "framer-motion";
import "./spot.scss";

const videoAnimations = {
	play: {
		scale: 1.1,
	},
	pause: {
		scale: 1,
	},
};

const spotAnimations = {
	initial: {
		x: 0,
		transitionEnd: { zIndex: 1 },
		left: 0,
		top: 0,
		right: 0,
	},
	maximized: {
		x: 0.0001,
		zIndex: 9,
		left: 0,
		top: 0,
		right: 0,
	},
};

const spotHeroAnimations = {
	maximized: {
		borderRadius: 0,
	},
};

const Spot = ({
	title,
	video,
	bgImage,
	bgColor,
	bgGradient,
	ratio = "landscape",
	theme,
	description,
	labels = [],
	logo,
	position,
	isMaximized,
	to,
	customTheme,
}) => {
	const wrapperRef = React.useRef(null);
	const videoRef = React.useRef(null);
	const [spotHeight, setSpotHeight] = useState(0);
	const isInView = useInView(videoRef, {
		margin: "0px 0px -10% 0px",
	});

	return (
		<motion.div
			className={`spot ${
				position === "left" ? "left" : position === "first" ? "first" : ""
			}`}
		>
			<div className="spotLogoContainer">
				<div className="spotCorner" />
				<GradientBox
					className="spotLogo"
					borderRadius="44rem"
					borderWidth="2px"
					padding="2rem"
				>
					<img
						src={logo}
						height="32"
						style={{ display: "block" }}
						alt={`${to}`}
					/>
				</GradientBox>
			</div>

			<Link
				to={to}
				className="spotLink"
				activeStyle={{ height: spotHeight + "px" }}
				ref={wrapperRef}
				onClick={(e) => setSpotHeight(e.currentTarget.clientHeight)}
			>
				{/* <div className="spotHoverBg" /> */}
				<motion.div
					className={`spotWrapper ${isMaximized && `maximized`}`}
					variants={spotAnimations}
					initial="initial"
					animate={isMaximized ? "maximized" : "initial"}
					layout
				>
					<motion.div
						style={{
							borderRadius: "var(--spot-borderRadius)",
							backgroundImage: bgGradient,
						}}
						className={`${ratio} spotHeader`}
						data-theme={theme}
						layout
						initial={false}
						animate={isMaximized ? "maximized" : "initial"}
						variants={spotHeroAnimations}
					>
						<div data-theme={customTheme} className="spotHoverIcon">
							<ArrowUpLeft size="32" />
						</div>
						<motion.div layout="position" className="spotInfo">
							<motion.div layout="position">
								<div className="spotTitleContainer">
									<h2 className="display text spotTitle">{title}</h2>
								</div>
								<p className="text spotDescription">{description}</p>
								<motion.div className="labels" layout="position">
									{labels.map((label) => (
										<div key={label} className="label text">
											{label}
										</div>
									))}
								</motion.div>
							</motion.div>
						</motion.div>
						<motion.div className="spotVideoWrapper" layout>
							<motion.div
								layout
								className="spotVideoBackground"
								style={{
									backgroundImage: `url(${bgImage})`,
									backgroundColor: bgColor,
								}}
							/>
							<motion.div
								layout
								className={`${ratio} spotVideoContainer`}
								variants={videoAnimations}
								initial="initial"
								animate={isInView || isMaximized ? "play" : "pause"}
							>
								<div className="addressBar">
									<div className="addressBarButtons">
										<div className="addressBarButton" />
										<div className="addressBarButton" />
									</div>
									<div className="addressBarSearch" />
								</div>
								<video
									className="spotVideo"
									muted
									ref={videoRef}
									loop
									playsInline
									autoPlay
								>
									<source src={video} type="video/mp4" />
								</video>
							</motion.div>
						</motion.div>
					</motion.div>
				</motion.div>
			</Link>
		</motion.div>
	);
};

export default Spot;
